.plumbing-container {
  margin: 20px;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.plumbing-container h1 {
  color: #275D8C; /* Deep blue color, reminiscent of water; adjust as needed */
  text-align: center;
  margin-bottom: 20px;
}

.plumbing-container h2 {
  color: #3A719F; /* Medium blue; adjust as needed */
  margin-top: 20px;
}

.plumbing-container p {
  text-align: justify;
  margin-top: 10px;
}

.plumbing-container section {
  margin-bottom: 30px;
}

/* Styles for video and affiliate link containers */
.video-affiliate-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.video-container, .affiliate-container {
  flex: 1; /* Adjust the flex ratio as needed */
  margin: 10px;
}

.affiliate-container {
  width: 120px; /* Adjust as needed for the affiliate iframe */
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .video-affiliate-section {
    flex-direction: column;
    align-items: center;
  }

  .video-container, .affiliate-container {
    width: 100%;
    max-width: 560px; /* Adjust the max-width as needed */
  }
}

/* New CSS for 'Back to Home' button */
.plumbing-container a {
  display: inline-block;
  background-color: #0055A5; /* Button color */
  color: white; /* Text color */
  padding: 10px 20px; /* Padding for size */
  border-radius: 5px; /* Rounded corners */
  text-align: center;
  text-decoration: none; /* Removes underline */
  margin-bottom: 20px; /* Space below the button */
  font-size: 16px; /* Font size */
}

.plumbing-container a:hover {
  background-color: #003366; /* Darker color on hover */
}

@media (max-width: 768px) {
  .plumbing-container a {
      width: 100%; /* Full width on small screens */
      box-sizing: border-box; /* Includes padding in width */
  }
}

/* Add any additional styling or media queries for responsiveness as needed */
/* Typewriter effect */
@keyframes typewriter {
  from { width: 0; }
  to { width: 100%; }
}

.typewriter-text {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 2px;
  animation: typewriter 4s steps(40) 1s 1 normal both;
}

/* Typewriter effect for repeating text */
@keyframes repeat-typewriter {
  0%, 100% { width: 0; }
  50% { width: 100%; }
}

.repeat-typewriter-text {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 2px;
  animation: repeat-typewriter 2s steps(10) infinite; /* Adjust speed as needed */
}

/* Style for the affiliate link */
.affiliate-text-link {
  display: inline-block;
  /* ... other styles ... */
}

/* ... any additional styling ... */