.roofing-container {
    margin: 20px;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .roofing-container h1 {
    color: #4C3B4D; /* Dark earthy color, suggesting stability; adjust as needed */
    text-align: center;
    margin-bottom: 20px;
  }
  
  .roofing-container h2 {
    color: #5A5366; /* Medium earthy purple; adjust as needed */
    margin-top: 20px;
  }
  
  .roofing-container p {
    text-align: justify;
    margin-top: 10px;
  }
  
  .roofing-container section {
    margin-bottom: 30px;
  }
  
  .video-affiliate-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .video-affiliate-wrapper {
        flex-direction: column;
    }
}

/* New CSS for 'Back to Home' button */
.roofing-container a {
  display: inline-block;
  background-color: #0055A5; /* Button color */
  color: white; /* Text color */
  padding: 10px 20px; /* Padding for size */
  border-radius: 5px; /* Rounded corners */
  text-align: center;
  text-decoration: none; /* Removes underline */
  margin-bottom: 20px; /* Space below the button */
  font-size: 16px; /* Font size */
}

.roofing-container a:hover {
  background-color: #003366; /* Darker color on hover */
}

@media (max-width: 768px) {
  .roofing-container a {
      width: 100%; /* Full width on small screens */
      box-sizing: border-box; /* Includes padding in width */
  }
}

  /* Add any additional styling or media queries for responsiveness as needed */
  