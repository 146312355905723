.concrete-container {
  margin: 20px;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.concrete-container h1 {
  color: #004d40; /* Deep teal color; adjust as needed */
  text-align: center;
  margin-bottom: 20px;
}

.concrete-container h2 {
  color: #00796b; /* Teal color; adjust as needed */
  margin-top: 20px;
}

.concrete-container h3 {
  color: #009688; /* Light teal color; adjust as needed */
  margin-top: 15px;
}

.concrete-container p {
  text-align: justify;
  margin-top: 10px;
}

.concrete-container section {
  margin-bottom: 30px;
}

.concrete-container ul {
  list-style-position: inside;
}

.concrete-container li {
  margin-bottom: 10px;
}

/* New styling for video and affiliate wrapper */
.video-affiliate-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px; /* Adjust the spacing between items */
}

.video-affiliate-wrapper iframe {
  border: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .video-affiliate-wrapper {
      flex-direction: column; /* Stack items vertically on smaller screens */
  }

  .video-affiliate-wrapper iframe {
      width: 100%;
      max-width: 560px; /* Adjust the max width of the video and iframe */
  }
}

/* New CSS for 'Back to Home' button */
.concrete-container a {
  display: inline-block;
  background-color: #0055A5; /* Button color */
  color: white; /* Text color */
  padding: 10px 20px; /* Padding for size */
  border-radius: 5px; /* Rounded corners */
  text-align: center;
  text-decoration: none; /* Removes underline */
  margin-bottom: 20px; /* Space below the button */
  font-size: 16px; /* Font size */
}

.concrete-container a:hover {
  background-color: #003366; /* Darker color on hover */
}

@media (max-width: 768px) {
  .concrete-container a {
      width: 100%; /* Full width on small screens */
      box-sizing: border-box; /* Includes padding in width */
  }
}

/* Additional media queries and styles as needed */
