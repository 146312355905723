/* Home.css */

.home-container {
  text-align: center;
  margin: 20px;
}

.video-list {
  margin: 20px 0;
}

/* Style for Video and Affiliate Link Wrapper */
.video-affiliate-wrapper {
  margin-bottom: 40px; /* Space between each video and the next button */
}

/* Style for Affiliate Link Button */
.video-affiliate-wrapper a {
  display: inline-block; /* Inline-block for proper button formatting */
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  font-weight: bold; /* Make text bold */
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Subtle shadow for depth */
}

.video-affiliate-wrapper a:hover {
  background-color: #367c39; /* Slightly darker green on hover */
}

.category-links {
  list-style: none;
  padding: 0;
  display: flex; /* Use Flexbox */
  flex-wrap: wrap; /* Wrap items to next line */
  justify-content: center; /* Center items horizontally */
}

.category-link {
  display: inline-block;
  margin: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  min-width: 150px; /* Ensure consistent width */
}

.category-link:hover {
  background-color: #0056b3;
}

/* Style for the affiliate disclosure */
.affiliate-disclosure {
  text-align: center;
  margin-bottom: 20px;
  font-size: 0.9em;
  color: #666;
}

/* Style for the disclaimer */
.disclaimer {
  text-align: center;
  margin-bottom: 20px;
  font-size: 0.9em;
  color: #666;
  padding: 0 20px; /* Add padding for better readability on small screens */
}

/* Mobile Responsiveness */
@media screen and (max-width: 768px) {
  .category-links {
    flex-direction: column; /* Stack links vertically on small screens */
  }

  .category-link {
    min-width: 100%; /* Full width for mobile view */
    margin: 5px 0; /* Reduced margin for mobile */
  }

  .video-affiliate-wrapper a {
    width: 100%; /* Full width button for mobile */
    box-sizing: border-box; /* Include padding and border in width */
  }
}
