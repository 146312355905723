.drywall-container {
  margin: 20px;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.drywall-container h1 {
  color: #3E2723; /* Deep brown; adjust as needed for drywall theme */
  text-align: center;
  margin-bottom: 20px;
}

.drywall-container h2 {
  color: #5D4037; /* Medium brown; adjust as needed */
  margin-top: 20px;
}

.drywall-container h3 {
  color: #795548; /* Light brown; adjust as needed */
  margin-top: 15px;
}

.drywall-container p {
  text-align: justify;
  margin-top: 10px;
}

.drywall-container section {
  margin-bottom: 30px;
}

.drywall-container ul {
  list-style-position: inside;
}

.drywall-container li {
  margin-bottom: 10px;
}

/* New styles for video and affiliate iframe wrapper */
.video-affiliate-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .video-affiliate-wrapper {
      flex-direction: column;
  }
}

/* New CSS for 'Back to Home' button */
.drywall-container a {
  display: inline-block;
  background-color: #0055A5; /* Button color */
  color: white; /* Text color */
  padding: 10px 20px; /* Padding for size */
  border-radius: 5px; /* Rounded corners */
  text-align: center;
  text-decoration: none; /* Removes underline */
  margin-bottom: 20px; /* Space below the button */
  font-size: 16px; /* Font size */
}

.drywall-container a:hover {
  background-color: #003366; /* Darker color on hover */
}

@media (max-width: 768px) {
  .drywall-container a {
      width: 100%; /* Full width on small screens */
      box-sizing: border-box; /* Includes padding in width */
  }
}

.tree-graph {
  background-color: #2c3e50; /* Dark blue background */
  color: #ecf0f1; /* Light grey text */
  font-family: 'Courier New', monospace;
  padding: 20px;
  border-radius: 5px;
  line-height: 1.6;
  display: inline-block; /* This makes the background only as wide as the content */
  margin: 0 auto; /* This will center the tree graph if the parent container allows centering */
  box-sizing: border-box;
}

.tree-graph > p {
  font-weight: bold;
  color: #f1c40f; /* Yellow color for the main title */
  margin-bottom: 10px;
}

.branch > p {
  color: #3498db; /* Blue color for the branch titles */
  margin: 0 0 5px 0; /* Adjust margin for branch titles */
}

.branch {
  padding-left: 20px;
  position: relative;
}

.branch:before {
  content: '';
  position: absolute;
  top: 10px; /* Align with the middle of the branch title */
  left: 0;
  height: calc(100% - 10px); /* Full height minus branch title height */
  border-left: 2px solid #95a5a6; /* Grey color for the lines */
}

.branch:after {
  content: '';
  position: absolute;
  top: 10px; /* Align with the middle of the branch title */
  left: 0;
  width: 10px;
  border-top: 2px solid #95a5a6; /* Grey color for the lines */
}

.branch:first-child:before {
  top: 50%; /* Start from the middle for the first branch */
}

.branch:last-child:before {
  height: 50%; /* Half height for the last branch */
}

.branch:not(:first-child) > p:before {
  content: '├─';
  position: absolute;
  left: -20px;
  color: #95a5a6; /* Grey color for the branch connector */
}

.branch:last-child > p:before {
  content: '└─'; /* Different connector for the last branch */
}

.branch ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.branch ul li {
  position: relative;
  padding-left: 20px;
}

.branch ul li:before {
  content: '—'; /* Dash for the list items */
  position: absolute;
  left: -20px;
  color: #95a5a6; /* Grey color for the dash */
}

/* Media query for responsiveness on smaller screens */
@media (max-width: 768px) {
  .tree-graph {
      padding: 10px;
      font-size: 14px; /* Slightly smaller font size on mobile */
  }

  .branch {
      padding-left: 15px;
  }

  .branch:before,
  .branch:after,
  .branch:not(:first-child) > p:before,
  .branch:last-child > p:before,
  .branch ul li:before {
      left: -15px; /* Adjust the position of the lines and connectors for mobile */
  }
}
